.footer {
  background: var(--box-bg-color);
  color: var(--text-color-muted);
  border-top: none;
  padding: 20px 1rem;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $common-custome-dark-font-family-regular;
  border-top: var(--border-color-nav-sider-bar);
  position: relative;
  
  a {
    color: $common-theme-color;
    font-size: 13px;
  }
  .footer-shree{
    position: absolute;
    right: 10px;
    top: 16px;
    .img-sm {
      width: 25px;
      height: 25px;
    }
}
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  background-image: linear-gradient(#393c3d, gray);
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar:hover {
  width: 5px;
  height: 5px;
  background-image: linear-gradient(#40484a, gray);
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-track:hover {
  width: 5px;
  height: 5px;
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-image: repeating-linear-gradient(#3a4042, darkgray);
  border: 0px solid #0e78f3;
}

/*Modal Css Start*/
.modal-dark .modal-title-box h3 {
  font-size: 21px;
  color: #fff;
  margin-bottom: 0px;
  font-family: "GOTHICB";
  display: flex;
  align-items: center;
}
.modal-dark .modal-title-box h3 svg {
  margin-right: 10px;
}
.modal-dark .modal-title-box h3 svg path {
  stroke: #fff;
}
.modal-dark .modal-title-box h3 svg rect {
  fill: #fff;
}
.modal-dark .modal-title-box h3 svg g {
  fill: #000;
}
.modal-dark .modal-title-box h3 svg g rect {
  fill: #000;
}
.modal-dark .modal-title-box span {
  font-family: "GOTHIC";
  font-size: 16px;
  color: #fff;
  margin-right: 30px;
  display: -webkit-inline-box;
}
.modal-dark .modal-title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-dark .modal-header button.close {
  background: #f00;
  position: absolute;
  top: -1px;
  right: -2px;
  color: #fff;
  margin: 0px;
  border-bottom-left-radius: 50px;
  padding: 5px 8px 10px 12px;
  border-top-right-radius: 0.3rem;
  z-index: 999;
}
.modal-dark .modal-header button.close span {
  display: block;
  transition: 0.5s linear;
}

.modal-dark .modal-header button.close:hover span {
  transform: scale(1.3);
}
.modal-dark .modal-header {
  position: relative;
  border-bottom: 1px solid #0b637461;
}
.modal-dark .table {
  border: 1px solid #0b637461;
}
.modal-dark .modal-footer {
  border-top: 1px solid #0b637461;
}
.modal-dark h3.student-list-box-title,
.modal-dark h3.faculty-list-box-title {
  color: #2196f3;
  font-size: 18px;
  margin-bottom: 25px;
  font-family: "GOTHIC";
}
.modal-dark h3.student-list-box-title svg {
  margin-right: 10px;
}

.modal-dark .modal-body {
  max-height: 78vh;
  height: 100%;
  overflow-y: auto;
  transition: 0.8s linear;
}
.modal-dark .table th {
  font-size: 14px;
  font-family: "GOTHICB";
}
.modal-dark .table td {
  font-size: 14px;
  font-family: "GOTHIC";
}

.modal.modal-dark {
  background: #0404049c;
  transition: 0.8s linear;
}
.modal-dark button.uploadeBtn {
  background: transparent;
  border: 1px solid #36ab21;
  border-radius: 20px;
  color: #fff;
  padding: 5px 15px;
  font-size: 0.875rem;
  font-family: "GOTHIC";
  /* box-shadow: 0px 0px 12px 0px #36ab21; */
  transition: 0.5s linear;
  border-radius: 25px;
}
.modal-dark button.uploadeBtn:hover {
  background: #36ab21;
  color: #fff;
}

.modal-dark button.closeBtn {
  background: transparent;
  border: 1px solid #7a7f78;
  border-radius: 20px;
  color: #fff;
  padding: 5px 15px;
  font-size: 0.875rem;
  font-family: "GOTHIC";
  /* box-shadow: 0px 0px 12px 0px #7a7f78; */
  transition: 0.5s linear;
  border-radius: 25px;
}

.modal-dark button.closeBtn:hover {
  background: #7a7f78;
}
.modal-dark button.previousBtn {
  background: transparent;
  border: 1px solid #2196f3;
  border-radius: 20px;
  color: #fff;
  padding: 5px 15px;
  font-size: 0.875rem;
  font-family: "GOTHIC";
  /* box-shadow: 0px 0px 12px 0px #7a7f78; */
  transition: 0.5s linear;
  border-radius: 25px;
}
.modal-dark button.previousBtn:hover {
  background: #2196f3;
}
.action-btn-wrap .uploadeBtn {
  margin-right: 10px;
}
.modal-dark .form-check-label {
  /* color: #ffffff4a; */
  color: #ffffff73;
  font-family: "GOTHIC";
}
.modal-dark .modal-content {
  background-color: #020202;
  background-clip: padding-box;
  border: 1px solid #000;
  box-shadow: 0px 0px 7px 0px #18dcff;
  border-radius: 0.3rem;
  transition: 0.8s linear;
}
/* .modal-dark .form-check .form-check-label input[type="checkbox"] + .input-helper:after{
box-shadow: none;
} */
.modal-dark textarea.form-control {
  resize: vertical;
}
/*Modal Css End*/
#root {
  position: fixed;
  background: url(../../assets/images/dashboard/DashBoard-BG.png) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.modalFortestTimingWrappper {
  transition: 0.8s linear;
}
.modal.show .modal-dialog {
  transform: none;
  transition: 0.5s linear !important;
}

.modalAssignPreviewWrap .accordian-wrapper-main {
  clear: both;
  margin-bottom: 10px;
}
.test-name-box h3 {
  font-size: 16px;
  color: #fff;
  font-family: "GOTHICB";
  margin-bottom: 20px;
}
.accordian-wrapper-main .accordion {
  margin-bottom: 10px;
  transition: 0.3s linear;
  cursor: pointer;
}
.accordian-wrapper-main .accordion:hover {
  box-shadow: 0px 3px 6px -4px #18dcff;
  transform: translateY(-5px);
  border-radius: 30px;
}
.accordian-dark-wrapper .card-header {
  padding: 0px;
  border: 0px;
}
.modalAssignPreviewWrap .accordian-wrapper-main .card {
  border-radius: 30px !important;
}
.modalAssignPreviewWrap .accordian-wrapper-main .card-header {
  border-bottom: 1px solid #ffffff17;
}
.modalAssignPreviewWrap .accordian-wrapper-main .card-header span {
  font-size: 13px;
  color: #888686;
  font-family: "GOTHIC";
}

.accordian-content .form-control-placeholder {
  top: -10px;
}
.accordian-text-box {
  margin-bottom: 35px;
}
.accordian-content .accordian-text-box p {
  font-size: 14px;
  font-family: "GOTHIC";
}
.modalAssignPreviewWrap .accordian-wrapper-main .accordion > .card .card-header span svg {
  margin: 0px 5px;
}

::-webkit-calendar-picker-indicator {
  color: #18dcff;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.accordian-content {
  background: #1e222d;
  padding: 10px;
  margin-bottom: 8px;
}
.modalAssignPreviewWrap .accordian-wrapper-main .accordion .card .card-body {
  padding: 15px;
}
.buffer-time-text-box input {
  /* width: 100px;  */
}
@media (min-width: 576px) {
  .modal-dark .modal-md {
    max-width: 815px;
  }
  .ongoing-modal-dark .modal-lg {
    max-width: 980px;
  }
  .subject-mapping-modal .modal-sm {
    max-width: 525px;
  }
  .modal-dark.code-change-modal .modal-sm {
    max-width: 430px;
  }
  .modal-dark.confirmation-modal-dark .modal-sm {
    max-width: 525px;
  }
  .modal-dark.attempted-exam-modal .modal-md{
    max-width: 1000px;
  }
  .modal-dark.details-report-modal .modal-md{
    max-width: 1000px;
  }
}
/* .tost-dark-container .Toastify__progress-bar {
    background-color: rgb(0 0 0 / 70%);
} */
.tost-dark-container .Toastify__toast {
  border-radius: 5px;
}
.tost-dark-container .Toastify__toast-body {
  font-family: "GOTHIC";
  font-size: 14px;
}
.modal-dark.exam-report-modal-dark .modal-header button.close,
.modal-dark.live-exam-report-modal-dark .modal-header button.close {
  z-index: 999;
  border-radius: 50px;
  opacity: 1;
  padding: 5px 10px;
  top: -18px;
  right: -18px;
}
.modal-dark.exam-report-modal-dark .modal-content,
.modal-dark.live-exam-report-modal-dark .modal-content {
  border-top-left-radius: 0px;
}

.modal-dark .breadcrumb {
  margin-bottom: 0px;
  font-size: 15px;
  font-family: "GOTHIC";
  color: #2196f3;
  padding: 0px;
}
.modal-dark .create-popup-main-content {
  margin: 20px 0px;
}
canvas {
  width: 100%;
  height: 100%;
}
.code-change-stude-name span,
.code-change-stude-email span {
  font-size: 14px;
  font-family: "GOTHIC";
  color: #bfb0b0;
  margin-left: 8px;
}
.code-change-stude-name label,
.code-change-stude-email label {
  font-family: "GOTHICB";
  color: #bfb0b0;
  font-size: 15px;
}
.code-change-stude-name,
.code-change-stude-email {
  margin-bottom: 0px;
}
.form-check.form-check-neonBlack .form-check-label input[type="checkbox"] + .input-helper:after {
  color: #fff;
}

.dispFlex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.hand-pointer{
    -webkit-animation-duration: 5s; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
  animation-timing-function: ease-in;
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
}
.hand-pointer svg{
    font-size: 50px;
    margin-right: 10px;
    color:#fff;
    
}
.dispFlex span{
  font-size: 21px;
}
.dispFlex a{
  font-size: 21px !important;
  margin-left: 10px;
}
    
@-webkit-keyframes shake {
  0%, 100% {-webkit-transform: translateX(0);} 
  10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-8px);} 
  20%, 40%, 60%, 80% {-webkit-transform: translateX(8px);} 
}

@keyframes shake { 
  0%, 100% {transform: translateX(0);} 
  10%, 30%, 50%, 70%, 90% {transform: translateX(-8px);} 
  20%, 40%, 60%, 80% {transform: translateX(8px);} 
}

.shake { 
  -webkit-animation-name: shake; 
  animation-name: shake; 
}
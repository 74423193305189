.explorerMainWrapper {
  .explorerMainHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $explorer-main-header-padding;
    background: $explorer-main-header-bg-color;
    border-top: $explorer-main-header-border-top;
  }
  .explorerTitleWrapper h3 {
    font-size: $explorer-title-font-size;
    color: $white;
    margin: 0px;
    font-family: $common-custome-dark-font-family-bold;
  }
  .explorerSearchWrapper {
    width: 252px;
    input.form-control {
      border: $explorer-border-zero;
      background: $bg-transparent;
      border-bottom: 1px solid $white;
      border-radius: 0px;
      color: $white;
      font-size: $default-font-size;
      padding-left: 0px;
      height: 40px;
    }
    .btn.btn-outline-secondary {
      border: $explorer-border-zero;
      color: $white;
      border-bottom: 1px solid $white;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease-in-out;
      border-radius: 0px;
      i {
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        background-color: $bg-transparent;
        color: $primary;
        border-color: $primary;
      }
      &:hover i {
        transform: scale(1.3);
      }
    }
  }
  .explorerColWrapper {
    background: $explorer-col-bg;
  }

  .explorerScrollableWrapper {
    width: 100%;
    overflow-x: auto;
    .explorerHorizontalWrapper {
      display: -webkit-box;
      height: 100%;
      // background: $black;
      /* width: 100%; */
      .explorerColHeaderWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px 5px 5px;
        border-bottom: 1px solid #2d2b2b;
        .explorerColHeaderTitle {
          h5 {
            font-size: $explorer-col-header-title-size;
            color: $explorer-col-header-title-color;
            margin: 0px;
            font-family: $common-custome-dark-font-family-regular;
          }
        }
        .explorerColHeaderCreate {
          background: $bg-transparent;
          padding: 0px 3px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          button.pluseBtnCommon {
            background: $bg-transparent !important;
            border: $explorer-border-zero;
            padding: 3px;
            color: $white;
            font-size: $explorer-plusebtn-font-size;
            i {
              margin-right: 0px;
            }
          }
        }
      }
      button.commonListNameBtn {
        background: $bg-transparent !important;
        border: $explorer-border-zero;
        font-size: $explorer-btn-text-font-size;
        width: 100%;
        text-align: left;
        padding: 7px;
        padding-left: 5px;
        font-family: $common-custome-dark-font-family-regular;
        .dark-count {
          color: #888686;
        }
      }
      ul.commonListContent {
        padding-left: 0px;
        list-style: none;
        border-bottom: 1px solid #292929;
        margin-bottom: 0px;
        li {
          display: flex;
          align-items: center;
        }
        .form-check {
          margin-left: 10px;
        }
      }
      .explorerRowHeaderWrap {
        display: -webkit-box;
        width: 100%;
      }
      .explorerRowWrapper {
        width: 100%;
        .explorerRowContentListWrap {
          display: -webkit-box;
          height: 68vh;
          background: #1b1b1b;
        }
        .explorerColContentListWrap {
          // width: 257px;
          border-right: 4px solid #000;
          resize: horizontal;
          overflow: hidden;
          .explorerColContentscroll {
            overflow: auto;
            height: 100%;
            position: relative;
            padding-bottom: 65px;
          }
        }
        .explorerColContentListWrap:nth-last-child(1) {
          border-right: $explorer-border-zero;
        }
        .explorerColHeaderWrap:nth-last-child(1) {
          border-right: $explorer-border-zero;
        }
      }
      .content-wrapper h1 {
        color: #929292;
        font-size: 24px;
      }
      .currentSelect {
        background: $explorer-current-select !important;
        border: $explorer-border-zero;
        font-size: $explorer-btn-text-font-size;
        width: 100%;
        text-align: left;
        padding: 7px;
        padding-left: 5px;
        font-family: $common-custome-dark-font-family-regular;
        border-radius: 0px;
        .combi-batch-menu {
          display: flex;
          align-items: center;
          .option-sub-menu {
            margin-left: 5px;
          }
        }
        .dark-count {
          color: #fff;
        }
      }
      .prevSelect {
        /*background:#4c4747 !important;*/
        background: $explorer-prev-select !important;
        border: $explorer-border-zero;
        font-size: $explorer-btn-text-font-size;
        width: 100%;
        text-align: left;
        padding: 7px;
        padding-left: 5px;
        font-family: $common-custome-dark-font-family-regular;
        border-radius: 0px;
        .combi-batch-menu {
          display: flex;
          align-items: center;
          .option-sub-menu {
            margin-left: 5px;
          }
        }
        .dark-count {
          color: #888686;
        }
      }
      button.prevSelect.btn.btn-primary:hover,
      button.prevSelect.btn.btn-primary:focus,
      button.prevSelect.btn.btn-primary:active {
        border-color: transparent;
        box-shadow: none !important;
      }
      button.currentSelect.btn.btn-primary:hover,
      button.currentSelect.btn.btn-primary:focus,
      button.currentSelect.btn.btn-primary:active {
        border-color: transparent;
        box-shadow: none !important;
      }
    }
  }
}

.explorerSearchWrapper
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
.explorerSearchWrapper
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .explorerSearchWrapper .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: #fff7f7;
}
.explorerSearchWrapper
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.explorerSearchWrapper
  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .explorerSearchWrapper .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
button.commonListNameBtn.btn-primary:not(:disabled):not(.disabled):active:focus,
button.commonListNameBtn.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > button.commonListNameBtn.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.student-file .explorerColContentListWrap {
  width: 257px;
  background: #1b1b1b;
  overflow: auto !important;
}
.student-file .commonListNameBtn,
.student-file .currentSelect,
.student-file .prevSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-file {
  .explorerColStudentProfile {
    width: 457px;
    .commonListContent {
      padding-left: 0px;
      list-style: none;
      // border-bottom: 1px solid #292929;
      margin-bottom: 0px;
      .profile-student-details {
        padding: 20px;
        .profile-student-img {
          text-align: center;
          margin-bottom: 20px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .profile-student-info-box {
          p {
            margin-bottom: 5px;
            font-size: 14px;
            color: #bfb0b0;
            font-family: $common-custome-dark-font-family-regular;
            label {
              font-size: 14px;
              color: #bfb0b0;
              font-family: $common-custome-dark-font-family-bold;
              margin-bottom: 0px;
              svg {
                margin-right: 10px;
              }
            }
          }
        }
        .attended-exam-box {
          text-align: center;
          padding: 50px 0px;
          button.attempted-exam-btm {
            background: $bg-transparent;
            border: 1px solid $btn-assign-border-color;
            padding: 5px 15px;
            color: $primary;
            font-family: $common-custome-dark-font-family-regular;
            font-size: $text-size-medium;
            text-align: center;
            transition: 0.5s linear;
            border-radius: 25px;
            &:hover {
              background: $primary;
              color: $white;
            }
          }
        }
      }
    }
  }
}
.attempted-exam-modal {
  .attempted-exam-list {
    .accordion {
      .card-header {
        background: #1b1b1b;
        margin-bottom: 10px;
        padding: 10px;
        cursor: pointer;
        transition: 0.5s linear;
        width: 100%;
        border-radius: 9px;
        border: 0px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        span {
          font-size: 16px;
          color: #888686;
          font-family: "GOTHIC";
          text-align: center;
        }
        span.checkbox-right-margin {
          svg {
            color: $green;
            font-size: 18px;
            margin-right: 10px;
            margin-left: 10px;
          }
        }
      }
      .attempted-list-content {
        padding: 10px;
        background: #060606;
        box-shadow: inset 0 -6px 5px -5px #18dcff;
        overflow: hidden;
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
        margin-bottom: 10px;
        .attempted-list-content-inner {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          align-items: center;
          span {
            color: #bfb0b0;
            font-family: $common-custome-dark-font-family-regular;
            font-size: $default-font-size;
          }
          span.subject-marks {
            span {
              margin: 0px 8px;
              font-family: $common-custome-dark-font-family-bold;
              label {
                font-family: $common-custome-dark-font-family-regular;
                margin-right: 8px;
                margin-bottom: 0px;
              }
            }
          }
          span.total-count {
            font-family: $common-custome-dark-font-family-bold;
            color: #fff;
            label {
              color: #fff;
              font-family: $common-custome-dark-font-family-regular;
            }
          }
        }
        .sub-even {
          background: #131111;
        }
        .sub-odd {
          background: #060606;
        }
      }
    }
  }
}
.details-report-modal {
  .details-report-tab {
    .tab-content {
      min-height: 27vh;
      max-height: 70vh;
      height: 100%;
      overflow-y: scroll;
      transition: 0.8s linear;
      background: #fff;
    }
    .nav.header-main-nav {
      border-bottom: 1px solid #313233;
      .nav-item {
        width: 25%;
        a.nav-link {
          color: #656464;
          font-size: 18px;
          border: none;
          border-bottom: 3px solid transparent;
          text-align: center;
          font-family: $common-custome-dark-font-family-regular;
          border-radius: 0px;
          &:hover {
            border: none;
            border-bottom: 3px solid transparent;
          }
        }
        a.nav-link.active {
          background: none;
          color: #bfb0b0;
          border-color: #2b90d9;
          position: relative;
          &:after {
            content: "";
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #2b90d9;
            position: absolute;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
          }
        }
      }
    }
    .answer-box {
      background: #fff;
      padding: 20px;
      min-height: 70vh;
      .accordion {
        .card-header {
          background: #1b1b1b;
          margin-bottom: 10px;
          padding: 10px;
          cursor: pointer;
          transition: 0.5s linear;
          width: 100%;
          border-radius: 9px;
          border: 0px;
          text-align: center;
          span {
            font-size: 16px;
            color: #888686;
            font-family: "GOTHIC";
            text-align: center;
          }
        }
        .details-report-content-accordian {
          padding: 0px 10px 10px 10px;
          .question-box {
            p {
              color: #000;
              font-family: $common-custome-dark-font-family-bold;
              font-size: 16px;
              span.question-number {
                margin-right: 8px;
                font-weight: 600;
              }
              span.question-text {
                img {
                  max-height: 270px;
                }
              }
              span.solution {
                color: #46c35f;
              }
            }
            ul {
              list-style: none;
              li {
                color: #000;
                font-family: $common-custome-dark-font-family-regular;
                font-size: 16px;
                display: flex;
                align-items: center;
                span.option-text {
                  img {
                    max-height: 200px;
                  }
                }
                span.wrong-check {
                  svg {
                    color: #f00;
                    font-size: 18px;
                    margin-right: 5px;
                  }
                }
                span.right-check {
                  svg {
                    color: $green;
                    font-size: 18px;
                    margin-right: 5px;
                  }
                }
                span.empty-check {
                  svg {
                    color: #787676;
                    font-size: 18px;
                    margin-right: 5px;
                  }
                }

                span.option-number {
                  font-weight: 600;
                  margin-right: 8px;
                }
                span.option-text {
                }
              }
            }
          }
          .quastion-box-attend {
            border-bottom: 2px solid #3f3e42;
          }
        }
      }
      .common-table-report {
        tr {
          th {
            text-align: center;
            padding: 10px;
            background: #2f9fde;
            border-right: 1px solid #000;
            border-left: 1px solid #000;
            p {
              font-size: 15px;
              color: #fff;
              font-weight: 600;
              margin: 0px;
            }
          }
        }
        tr {
          td {
            text-align: center;
            padding: 10px;
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            border-left: 1px solid #000;
            p {
              text-align: center;
              font-size: 15px;
              color: #000;
              font-weight: 600;
              margin: 0px;
              color: #2f9fde;
            }
          }
        }
      }
      .total-marks-table {
        padding: 0px 20px;
        margin-top: 20px;
        tr {
          td {
            text-align: center;
            padding: 10px;
            p {
              font-size: 15px;
              color: #000;
              font-weight: 600;
              margin: 0px;
            }
          }
          td.amt-td-left {
            text-align: left;
            padding: 10px;
            // border: 1px solid #000;
            border-right: 0px;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            background: #2f9fde;
            p {
              font-size: 15px;
              color: #fff;
              font-weight: 600;
              margin: 0px;
            }
          }
          td.amt-td-right {
            text-align: center;
            padding: 10px;
            // border: 1px solid #000;
            border-left: 0px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            background: #2f9fde;
            p {
              font-size: 15px;
              color: #fff;
              font-weight: 600;
              margin: 0px;
            }
          }
          td.amt-td-center {
            text-align: center;
            padding: 10px;
            // border: 1px solid #000;
            background: #2f9fde;
            p {
              font-size: 15px;
              color: #fff;
              font-weight: 600;
              margin: 0px;
            }
          }
        }
      }
      .table-report-3 {
        width: 100%;
        padding: 20px;
        margin-top: 20px;
        tr {
          td.table-report-3-td-1 {
            width: 33.33%;
            padding: 10px;
            text-align: center;
            border-right: 1px solid #000;
            p.top-p {
              font-size: 18px;
              color: #000;
              font-weight: 600;
              margin: 0px;
            }
            p.bottom-p {
              font-size: 14px;
              color: #000;
              font-weight: 400;
              margin: 0px;
            }
          }
          td.table-report-3-td-2 {
            text-align: center;
            width: 33.33%;
            padding: 10px;
            p.top-p {
              font-size: 18px;
              color: #000;
              font-weight: 600;
              margin: 0px;
            }
            p.bottom-p {
              font-size: 14px;
              color: #000;
              font-weight: 400;
              margin: 0px;
            }
          }
        }
      }
      .table-report-4 {
        width: 100%;
        padding: 20px;
        margin-top: 20px;
        tr {
          th.table-report-th-1 {
            text-align: center;
            padding: 10px;
            background: #000;
            border-right: 1px solid #fff;
            p {
              font-size: 15px;
              color: #fff;
              font-weight: 600;
              margin: 0px;
            }
          }
          th.table-report-th-2 {
            text-align: center;
            padding: 10px;
            background: #000;
            border-right: 1px solid #fff;
            p {
              font-size: 15px;
              color: #fff;
              font-weight: 600;
              margin: 0px;
            }
          }
        }
        tr {
          td.table-report-td-1 {
            text-align: center;
            padding: 10px;
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            border-left: 1px solid #000;
            p {
              font-size: 15px;
              color: #000;
              font-weight: 600;
              margin: 0px;
              color: #000;
            }
          }
          td.table-report-td-2 {
            text-align: center;
            padding: 10px;
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            p {
              font-size: 15px;
              color: #000;
              font-weight: 600;
              margin: 0px;
            }
          }
        }
      }
    }
  }
}
.subject-List {
  border-bottom: 1px solid;
  padding: 10px;
  align-items: center;
  text-align: center;
}
.subject-name {
  padding: 10px;
}
.question-number-panel-view {
  border: 1px solid;
  border-radius: 10px;
  margin: 5px;

  color: black;
  display: flex;
  justify-content: center;
  background: #dddddd;
}
.question-number-panel-view-answer {
  background: #ccf8db;
  border: 1px solid;
  border-radius: 10px;
  margin: 5px;

  color: black;
  display: flex;
  justify-content: center;
}
.question-number-panel-view-bookmark {
  background: #cbcbf7;

  border: 1px solid;
  border-radius: 10px;
  margin: 5px;

  color: black;
  display: flex;
  justify-content: center;
}
.color-white {
  color: whitesmoke;
}
.count {
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
}
.count-text {
  text-align: center;
  padding: 5px;
  margin: 5px;
}
.border-bottom {
  border-bottom: 1px solid;
}
.bookamark-panle-default {
  background: #c6e0ec;
  display: flex;
  justify-content: flex-end;
}
.bookamark-panle-selected {
  background: #c1bef0;
  display: flex;
  color: white;
  justify-content: flex-end;
}
.answer-panle-selected {
  background: #bfe6cc;
  display: flex;
  color: white;
  justify-content: flex-end;
}
.bookmark-icon-span {
  font-size: 25px;
  margin-right: 30px;
}
.bookmark-icon-text {
  color: #000;
  font-size: 15px;
  font-family: $common-custome-dark-font-family-regular;
  margin-right: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
.margin-right-left-12 {
  margin-right: 12px;
  margin-left: 12px;
}

.background-color-green {
  background: #ddf8e6;
  padding: 10px;
}
.background-color-lowender {
  background: #e4e4f8;
  padding: 10px;
}

.question-box-quastion-box-attend {
  padding: 10px;
  background: white;
  margin: 10px 0px;
  ul {
    li {
      list-style: none;
      .wrap-span {
        .user-checkBox {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        span.option-number {
          color: #000;
          font-size: 15px;
          font-family: $common-custome-dark-font-family-bold;
          img {
            max-height: 200px;
          }
        }
        span.option-text {
          color: #000;
          font-size: 15px;
          font-family: $common-custome-dark-font-family-regular;
          img {
            max-height: 200px;
          }
        }
      }
    }
  }
  p {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
      border-color: #000;
      &:focus {
        border-color: #000;
      }
    }
    span.question-number {
      color: #000;
      font-size: 15px;
      font-family: $common-custome-dark-font-family-bold;
      margin-right: 5px;
      img {
        max-height: 200px;
      }
    }
    span.question-text {
      color: #000;
      font-size: 15px;
      font-family: $common-custome-dark-font-family-bold;
      img {
        max-height: 200px;
      }
    }
  }
  p {
    color: #000;
    font-size: 16px;
    font-family: $common-custome-dark-font-family-bold;
  }
}
.question-box-quastion-box-attend {
  margin: 0px !important;
  padding: 0px !important;
}
.user-checkBox {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.number-list {
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
}
.question-number-panel-view-UserAnswerCount {
  border: 1px solid;
  border-radius: 10px;
  margin: 5px;
  background: #ccf8db;
  color: black;
  display: flex;
  justify-content: center;
}
.question-number-panel-view-UserNotAnswerCount {
  border: 1px solid;
  border-radius: 10px;
  margin: 5px;
  background: #fc7a78;
  color: black;
  display: flex;
  justify-content: center;
}
.question-number-panel-view-UserBookmarkCount {
  border: 1px solid;
  border-radius: 10px;
  margin: 5px;
  background: #bfbff1;
  color: black;
  display: flex;
  justify-content: center;
}

.question-number-panel-view-UserVisitedCount {
  border: 1px solid;
  border-radius: 10px;
  margin: 5px;
  background: white;
  color: black;
  display: flex;
  justify-content: center;
}
.overflow-scroll {
  overflow: scroll;
  height: 70vh;
}
.panel-height {
  overflow: scroll;
  height: 65vh;
}
.bottom-container {
  position: relative;
}
.togle-icon {
  color: white;
  font-size: 25px;
}
.common-text {
  color: #bfb0b0;
  font-size: 14px;
  font-family: $common-custome-dark-font-family-regular;
}
.time-remain-text {
  color: #fff;
  font-size: 15px;
  font-family: $common-custome-dark-font-family-regular;
  display: block;
  margin-bottom: 10px;
}
.btn-test-submit {
  background: transparent;
  border: 1px solid white;
  padding: 5px 8px;
  color: white;
  font-family: "GOTHIC";
  font-size: 15px;
  text-align: center;
  transition: 0.5s linear;
  border-radius: 25px;
  /* box-shadow: 0 0 10px 3px white; */
  margin-left: 15px;
}
.btn-test-discard {
  background: transparent;
  border: 1px solid white;
  padding: 5px 8px;
  color: white;
  font-family: "GOTHIC";
  font-size: 15px;
  text-align: center;
  transition: 0.5s linear;
  border-radius: 25px;
  /* box-shadow: 0 0 10px 3px white; */
  margin-left: 15px;
  font-size: 12px;
}
.text-size-12 {
  font-size: 14px;
  color: #bfb0b0;
  font-family: $common-custome-dark-font-family-regular;
  margin-bottom: 0px;
}
.test-information.row {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.panel-height {
  border: 1px solid;
  overflow: scroll;
  height: 70vh;
}
.water-mark-box {
  color: white;
  align-items: center;
  // background-image: url("../../assets/images/SG_Logo.png");
  // background-position: center;
  // background-size: contain;
  // background-repeat: no-repeat;
  // opacity: 0.6;
  // filter:alpha(opacity = 60);
  // z-index: -1;
  position: relative;
}
.water-mark-box::after {
  content: "";
  //   background-image: url("../../assets/images/Instruction_BG1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.exam-instruction-text {
  span {
    font-size: 14px;
    color: #fff;
    font-family: $common-custome-dark-font-family-regular;
    margin-bottom: 10px;
    display: block;
  }
}
.footer-btn-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button.uploadeBtn {
    border: 0px;
    &:hover {
      background: transparent;
      color: #36ab21;
    }
  }
}
.main-question-panel-view {
  margin-left: 2%;
}
.main-exam-container {
  margin-top: 2%;
  margin-bottom: 2%;
}
.question-number-side-panel {
  justify-content: center;
}
.user-details-table {
  margin-top: 0px !important;
  tr {
    td {
      padding: 5px;
      p {
        font-size: 12px;
        color: #000;
        margin: 0px;
      }
    }
    .td.amt-td-name {
      text-align: left !important;
    }
    .amt-td-details {
      text-align: right !important;
    }
  }
}
.amt-td-details {
  text-align: right !important;
}

.text-on-question-panle {
  font-size: 13px !important;
  font-weight: unset !important;
  margin-bottom: 0px !important;
  .negative-marks {
    color: red;
  }
  .positive-marks {
    color: green;
  }
}
.text-on-question-panle-questiontype {
  font-size: 13px !important;
  font-weight: unset !important;
  margin-bottom: 0px !important;
  color: blue !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.margin-left-20 {
  margin-left: 20px;
}

.question-text {
  img {
    max-height: 270px !important;
  }
}
.margin-right-20 {
  margin-right: 20px;
}
.trash-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.font-bold {
  font-weight: bold;
}
.scroll-horizontal-wrap {
  overflow-x: auto;
  width: 100%;
}

// .NEETSectionA{
//             background:#1e90ff;
//               paddingBottom:4px;
//               paddingTop: 4px;
//               color:#fff;
//               fontWeight: "bold";
// }

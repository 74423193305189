
.openExam-main-div{
    display: flex;
    flex-direction: column;
    
    height: 100% ;
  
    //   .openExam-image{
    //     margin: 0px 0px 0px 0px;
    //     overflow: hidden;
    //     @media (max-width: 767px) {
    //     margin: -100px 0px 0px -100px;
    //     }
    //   }

}

      .openExam-image{
        margin: 0px 0px 0px 0px;
       
        @media (max-width: 767px) {
            object-fit:contain;
       
        }
      }

.btn-lunch{
    align-self: flex-end
}
.ExamTrail{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    // background-color: red;

    @media (max-width: 767px) {
        padding: 10px 0px;
        
    }
}

.openExamBtnDiv{
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    width: 100%;
    // background-color: red;
    
    // align-items:center;


    .TimeDate{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        
        // background-color: aqua;
        flex-direction: column;


    .div1{
        display: flex;
        justify-content: flex-end;
        align-items:center; 
        // background-color: cornflowerblue;
        height: 50%;
        margin-right: 10px;
        h3{
            box-sizing: border-box;
        font-size: 21px;
        color: #fff;
        justify-content: flex-end;
        font-family: $common-custome-dark-font-family-regular;
        margin-bottom: 15px;
        @media (max-width: 767px) {
            font-size: 14px;
        }
         
    }
    }
    .div2{
        display: flex;
        justify-content: flex-end;
        align-items:center ;
        // background-color: crimson;
        height: 50%;
        margin-right: 10px;
        h3{
            box-sizing: border-box;

            font-size: 21px;
        color: #fff;
        justify-content: flex-end;
        font-family: $common-custome-dark-font-family-regular;
        margin-bottom: 15px;
        @media (max-width: 767px) {
            font-size: 14px;
        }
        }

    }

    @media (max-width: 767px) {
        width: 60%;
        }
       
}

    .openExamBtnDivRow{
      
        width: 30%;
        flex-direction: column;
        // flex-direction: column;
        justify-content: flex-end;
        // background-color: #7bf321;
        align-items: flex-end;
        padding: 10px 0px;
        .launch{
            justify-content: flex-end;
            display: flex;
            height: 50%;
            align-items: center;
            padding: 4px 4px;
            // background-color: blue;
            // button{
            //     margin-left: 100px;
            //     align-self: flex-end;
            // }
            
        }
        .launch-trial{
            justify-content: flex-end;
            display: flex;
            align-items: center;
            // background-color: chocolate;
            height: 50%;
            padding: 4px 4px;

        }
        @media (max-width: 767px) {
        width: 40%;
        }
       

    }



}


.btn-launch-trial{
    
        background: transparent;
        border: 1px solid #2196f3;
        padding: 8px 4px;
        color: #2196f3;
        font-family: "GOTHICB";
        font-size: 15px;
        text-align: center;
        transition: 0.5s linear;
        border-radius: 25px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0 0 10px 3px rgba(15, 100, 253, 0.35);
        // margin-bottom: 10px;
        width: 165px;
        &:hover {
            background: #2196f3;
            color: #fff;
        }
        @media (max-width: 767px) {
            width: 110px;
            font-size: 12px;
            padding: 4px 2px;
        }
    }



.open-exam-wrapper-exam-wrapper{
    // background: transparent;
    padding: 0px;
     height: 100%;
.open-exam-wrapper{
    height: 100%;
    position: relative;
    .carousel{
         height: 100%;
         position: relative;
        .carousel-inner{
             height: 100%;
            .carousel-item{
                 height: 100%;
                 img{
                  height: 100%;   
                 }
                .carousel-caption{
                    background: #00000063;
                    h3{
                        font-size: 21px;
                        color: #fff;
                        font-family: $common-custome-dark-font-family-regular;
                        margin-bottom: 15px;
                    }
                    button.btn-launch{
                        background: transparent;
                        border: 1px solid #7bf321;
                        padding: 5px 8px;
                        color: #7bf321;
                        font-family: "GOTHICB";
                        font-size: 15px;
                        text-align: center;
                        transition: 0.5s linear;
                        border-radius: 25px;
                        // box-shadow: 0 0 10px 3px rgba(15, 100, 253, 0.35);
                        align-self: flex-end;
                        padding-right: 50px;
                        padding-left: 50px;
                        margin-bottom: 20px;
                        &:hover {
                            background: #7bf321;
                            color: #fff;
                        }
                    }
                    
                }
                // .banner-overlay{
                //     position: absolute;
                //     top:0px;
                //     left: 0px;
                //     width: 100%;
                //     height: 100%;
                //     background: #00000063;
                // }
            }
        }
       
      
    }
      .carousel-caption-bottom{
        position: absolute;
        bottom: 0px;
        left: 38%;
        padding: 12px 45px;
        background: #0000008f;
        z-index: 99999;
        margin: 0 auto;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
           h3{
                        font-size: 21px;
                        color: #fff;
                        font-family: $common-custome-dark-font-family-regular;
                        margin-bottom: 0px;
                    }
                    
        }

        .carousel-caption-bottom-right{
          position: absolute;
          bottom: 0px;
          right: 0px;
          padding: 0px;
          background: transparent; 
          z-index: 99999; 
         text-align: right;

          h3{
            font-size: 14px;
            color: #fff;
            font-family: $common-custome-dark-font-family-regular;
            margin-bottom: 5px; 
            text-align: center;
          }
                    button.btn-launch{
                        background: #17bba4;
                        border: 1px solid #17bba4;
                        padding: 8px 10px;
                        color: #fff;
                        font-family: "GOTHICB";
                        font-size: 15px;
                        text-align: center;
                        transition: 0.5s linear;
                        border-radius: 25px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        // box-shadow: 0 0 10px 3px rgba(23, 187, 164, 0.35);
                        margin-bottom: 20px;
                        width: 165px;
                        margin-top: 10px;
                        &:hover {
                            background: transparent;
                            box-shadow: 0 0 10px 3px rgba(23, 187, 164, 0.35);
                            color: #fff;
                        }
                    } 
             
        }
        .carousel-caption-top{
           position: absolute;
           justify-content:flex-end;
          top: 10px;
          right: 0px;
          padding: 0px; 
          background: transparent;
          z-index: 999;  
        }
}
}
.demo-exam-box{
    .btn-demo-exam{
        background: transparent;
        border: 1px solid #2196f3;
        padding: 8px 10px;
        color: #2196f3;
        font-family: "GOTHICB";
        font-size: 15px;
        text-align: center;
        transition: 0.5s linear;
        border-radius: 25px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0 0 10px 3px rgba(15, 100, 253, 0.35);
        margin-bottom: 0px;
         width: 165px;
        &:hover {
            background: #2196f3;
            color: #fff;
        } 
    }
}
.modal-dark.demo-exam-main-modal{
    .modal-body {
        max-height: 68vh;
      .carousel{
          .carousel-indicators{
            bottom: -18px;
                li{
                width: 18px;
                }
          }
            .carousel-inner{
                .carousel-item{
                    transition: initial;
                    img{
                    max-height: 64vh;
                    margin: 0 auto;  
                    }
                    .carousel-caption{
                        h3{
                            font-size: 21px;
                            color: #fff;
                            font-family: $common-custome-dark-font-family-regular;
                            margin-bottom: 15px;
                        }
                        button.btn-launch{
                            background: transparent;
                            border: 1px solid #7bf321;
                            padding: 5px 8px;
                            color: #7bf321;
                            font-family: "GOTHICB";
                            font-size: 15px;
                            text-align: center;
                            transition: 0.5s linear;
                            border-radius: 25px;
                            // box-shadow: 0 0 10px 3px rgba(15, 100, 253, 0.35);
                            align-self: flex-end;
                            padding-right: 50px;
                            padding-left: 50px;
                            margin-bottom: 20px;
                            &:hover {
                                background: #7bf321;
                                color: #fff;
                            }
                        }
                    }
                    .banner-overlay{
                        position: absolute;
                        top:0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background: #00000063;
                    }
                }
            }
        }
    }
}

.oval-thought-border {
position: relative;
    padding: 8px;
    margin: 1em auto 30px;
    border: 5px solid #17bba4;
    text-align: center;
    color: #333;
    background: #fff;
    -webkit-border-top-left-radius: 240px 140px;
    -webkit-border-top-right-radius: 240px 140px;
    -webkit-border-bottom-right-radius: 240px 140px;
    -webkit-border-bottom-left-radius: 240px 140px;
    border-radius: 210px / 150px;
}

/* creates the larger circle */
.oval-thought-border:before {
content: "";
    position: absolute;
    z-index: 10;
    bottom: -29px;
    right: 13px;
    width: 25px;
    height: 25px;
    border: 5px solid #17bba4;
    background: #fff;
    border-radius: 50px;
    display: block;
}

/* creates the smaller circle */
// .oval-thought-border:after {
// content: "";
//     position: absolute;
//     z-index: 10;
//     bottom: -60px;
//     right: 33px;
//     width: 25px;
//     height: 25px;
//     border: 5px solid #17bba4;
//     background: #fff;
//     border-radius: 25px;
//     display: block;
// }

.thought {
  background-color:#17bba4;
  padding:20px;
  border-radius:30px;
  min-width:40px;
  max-width:220px;
  min-height:40px;
  margin:20px;
  position:relative;
  text-align:center;
  hr{
    margin: 0 auto;
    border-top: 1px solid #fff;
    width: 80%;
  }
}
.thought:before,
.thought:after {
  content:"";
  background-color:#17bba4;
  border-radius:50%;
  display:block;
  position:absolute;
  z-index:-1;
}
.thought:before {
  width:44px;
  height:44px;
  top:-12px;
  left:28px;
  box-shadow:-50px 30px 0 -12px #17bba4;
}
.thought:after {
  bottom:-10px;
  right:26px;
  width:30px;
  height:30px;
  box-shadow:40px -34px 0 0 #17bba4,
             -28px -6px 0 -2px #17bba4,
             -24px 17px 0 -6px #17bba4,
             -5px 25px 0 -10px #17bba4;
  
}
